import React from "react";
import HeroSection from "../components/HeroSection";
import Project from "../components/Project"; // Import the Project component
import "./ProjectsPage.css";

function ProjectsPage() {
  return (
    <div className="projects-page">
      <HeroSection />
      <div className="projects-content-container">
        <h1>Explore My Projects</h1>
        <Project 
          title="Personal Website"
          description="This project is a personal site that showcases my abilities with HTML, CSS, React and Masonry."
          projectUrl="https://christialecia.com/"
        />
        <Project 
          title="Favorite Things App"
          description="Inspired by Oprah, I created this site to share my personal collection of favorites that have brought joy, inspiration, and comfort to my life. I hope these recommendations inspire you as they have inspired me! "
          projectUrl="https://christisfavoritethings.com/"
        />
        <Project 
          title="Christi's Blog"
          description="This blog is a space where I share my journey of healing, growth, and the life experiences that have shaped me along the way."
          projectUrl="https://christis.blog/"
        />
      </div>
    </div>
  );
}

export { ProjectsPage };



