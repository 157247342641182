import React from 'react';
import './Project.css'; // Import the CSS file

const Project = ({ title, description, projectUrl }) => {
  return (
    <div className="project">
      <h3>{title}</h3>
      <p>{description}</p>
      <a href={projectUrl} target="_blank" rel="noopener noreferrer">Visit Project</a>
    </div>
  );
};

export default Project;
