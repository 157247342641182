import React from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css"; // Ensure this points to your CSS file

function Navbar() {
  return (
    <nav className="navbar">
      <NavLink to="/" exact activeClassName="active">About</NavLink>
      <NavLink to="/projects" activeClassName="active">Projects</NavLink>
      <NavLink to="/resume" activeClassName="active">Resume</NavLink>
      <NavLink to="/contact" activeClassName="active">Contact</NavLink>
    </nav>
  );
}

export default Navbar;
// In this example, we updated the Navbar component to include a new Projects link. We also updated the About link to point to the AboutPage component. The other links remain the same.