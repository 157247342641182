import React from "react";
import HeroSection from "../components/HeroSection";
import "./ResumePage.css"; // Ensure this points to your CSS file

function ResumePage() {
  return (
    <div className="resume-page">
      <HeroSection />
      <div className="resume-container">
        <h1>My Professional Journey</h1>
        <p>Welcome to my resume - a timeline of my skills and experiences.</p>
        <section className="resume-centered-section">
          <h1>Programming Proficiency</h1>
          <br />
          <p>
            HTML, CSS, SQL, Python, JavaScript, React.JS. Strong analytical,
            problem-solving, and decision-making skills. Knowledgeable in
            software applications, design tools, and relational database
            management.{" "}
          </p>
          <br />
        </section>
        <section className="resume-centered-section">
          <h1>Certifications</h1>
          <br />
          <p>
            FAA Part 107 Certified Remote Pilot of a Small Unmanned Aircraft
            System (Drone)
          </p>
          <p>
          200-Hour Certified Yoga Teacher & Social-Emotional Learning Facilitator (SEL*F)
          </p>
          <br />
        </section>
        <section className="resume-centered-section">
          <h1>Technical Skills</h1>
          <br />
          <p>
            Proficient in PC-based Windows applications (Microsoft Office,
            EpicCare, Facets, McKesson Total Payment) Office Support: Leadership
            in billing, coding, documentation, registration, scheduling, and
            clinical activities.{" "}
          </p>
          <br />
        </section>
        <section className="resume-centered-section">
          <h1>Key Strengths</h1>
          <br />
          <p>
            Excellent communication and networking skills, quick mastery of new
            tasks and assignments. Organized, self-confident, dedicated, and
            open to challenge.
          </p>
          <br />
        </section>
        <section className="education-section">
          <h1>Education</h1>
          <br />
          <ul>
            <li>
              <strong>Computer Programming Certificate Program</strong>, Le
              Moyne College, 05/2024
            </li>
            <li>
              <strong>JavaScript Developer</strong>, MVCC Center for Corporate &
              Community Education, 01/2024
            </li>
            <li>
              <strong>
                Basic Drone Quadcopter Flight Skills and Part 107 Test Prep
              </strong>
              , SkyOp LLC, 04/2023,
              <br /> Certification 05/2023
            </li>
            <li>
              <strong>SQL Series</strong>, MVCC Center for Corporate & Community
              Education, 01/23
            </li>
            <li>
              <strong>200-Hour Certified Yoga Teacher & Social-Emotional Learning Facilitator</strong>, Breathe for Change, 09/2022
              Community Education, 10/2022
            </li>
            <li>
              <strong>Careers in Code</strong>, Full Stack Web Development Boot
              Camp, 03/2022-09/2022
            </li>
            <li>
              <strong>B.S. in Business Administration</strong> STATE UNIVERSITY
              OF NEW YORK (S.U.N.Y.) <br /> EMPIRE STATE COLLEGE, 07/2008
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export { ResumePage };
