import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import { AboutPage } from "./pages/AboutPage";
import { ContactPage } from './pages/ContactPage';
import { ProjectsPage } from './pages/ProjectsPage'; // Updated import
import { ResumePage } from './pages/ResumePage';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<AboutPage />} /> {/* Set as default route */}
        <Route path="/projects" element={<ProjectsPage />} /> {/* New route for projects */}
        <Route path="/resume" element={<ResumePage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;
